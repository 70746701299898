import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineNotify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_line_notify', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineNotifyOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/line_notify_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
